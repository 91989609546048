<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('subaccountlist_xq')">基本信息</span>
      <span @click="toTypeUrl('add_subaccountlist_js')">结算信息</span>
      <span id="fzcolor">配置信息</span>
    </div>
    <div class="header" v-else>
      <span @click="toUrl('add_subaccountlist')">基本信息</span>
      <span @click="toUrl('add_subaccountlist_js')">结算信息</span>
      <span id="fzcolor">配置信息</span>
    </div>
    <div v-auth="pageType == 'edit' ? 'ACCOUNT:ALLOC:MERCHNATS:CONFIG' : 'ALL'">
      <h5 class="jsxx">结算周期</h5>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>结算方式</p>
            </td>
            <td>
              <p>结算周期类型</p>
            </td>
            <td>
              <p>结算周期</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td style="width: 171px">
              <p>{{ settleType[v.settleType] }}</p>
            </td>
            <td style="width: 163px">
              <p>{{ cycleType[v.cycleType] }}</p>
            </td>
            <td style="width: 187px">
              <p>{{ v.settleDay }}</p>
            </td>
            <td style="width: 95px">
              <p>
                <i class="lianjie" @click="editTab(v, i)">编辑</i>
                <i
                  v-auth="
                    pageType == 'edit'
                      ? 'ACCOUNT:ALLOC:MERCHNATS:CONFIG/DELETE'
                      : 'ALL'
                  "
                  class="lianjie"
                  @click="delTab(i, v)"
                  >删除</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="
          pageType == 'edit' ? 'ACCOUNT:ALLOC:MERCHNATS:CONFIG/ADD' : 'ALL'
        "
        class="xzjsxx"
        @click="addTab"
        v-if="tabData.length < 1"
      >
        <span>+</span> 新增结算周期
      </h6>
      <!-- 	<div class="btn" v-if="pageType=='edit'">
					<el-button @click="toTypeUrl('add_subaccountlist_js')" class="back">上一步</el-button>
					<el-button @click="toUrl('subaccountlist_shlb')" class="next" type="primary">保存</el-button>
				</div> -->
      <div class="btn" v-if="pageType != 'edit'">
        <el-button @click="toUrl('add_subaccountlist_js')" class="back"
          >上一步</el-button
        >
        <el-button @click="submit" class="next" type="primary">保存</el-button>
      </div>
      <el-dialog
        :title="editIndex ? '修改结算周期' : '新增结算周期'"
        :visible.sync="userPayShow"
        width="490px"
      >
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>结算方式</div>
            <el-select
              v-model="formData.settleType"
              placeholder="选择结算方式"
              tabindex="1"
            >
              <el-option
                v-for="v in settleDrop"
                :key="v.value"
                :label="v.title"
                :value="v.value"
                :disabled="v.disabled"
              ></el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>结算周期</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="formData.settleDay"
              placeholder="输入结算周期"
              tabindex="3"
            ></el-input>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>结算周期类型</div>
            <el-select
              v-model="formData.cycleType"
              placeholder="选择结算周期类型"
              tabindex="2"
            >
              <el-option label="工作日(T)" value="WORK_DAY"></el-option>
              <el-option label="自然日(D)" value="NATURE_DAY"></el-option>
              <el-option label="月结日" value="MONTH_DAY"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="userPayShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAdd">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import {
  addAllocmerchants,
  getAllocmerchantsDetail,
  setProducts,
  delProducts,
} from "@/api/user/subaccount.js";
export default {
  data() {
    return {
      userPayShow: false, //新增支付展示
      formData: {
        settleType: "",
        cycleType: "",
        settleDay: "",
      },
      settleDrop: [
        {
          title: "自动结算",
          value: "AUTO",
          disabled: false,
        },
        {
          title: "手工结算",
          value: "MANUAL",
          disabled: false,
        },
        {
          title: "手工即时结算",
          value: "MANUAL_INSTANT",
          disabled: false,
        },
      ],
      settleType: {
        AUTO: "自动结算",
        MANUAL: "手工结算",
        MANUAL_INSTANT: "手工即时结算",
      },
      cycleType: {
        WORK_DAY: "工作日",
        NATURE_DAY: "自然日",
        MONTH_DAY: "月结日",
      },
      tabData: [],
      editIndex: "",
      pageType: "", //页面状态
    };
  },
  computed: {
    ...mapState({
      subaccountDetail: "user_subaccountDetail",
    }),
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.subaccountDetail) {
        if (this.subaccountDetail.products) {
          this.tabData = this.subaccountDetail.products;
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setSubaccount: "user_setSubaccount",
    }),
    //获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.tabData = res.resultData.products;
        }
      });
    },
    // 添加结算周期展示
    addTab() {
      this.formData = {
        settleType: "",
        cycleType: "",
        settleDay: "",
      };
      this.editIndex = null;
      this.userPayShow = true;
      this.settleDrop.forEach((v) => {
        v.disabled = false;
      });
      this.tabData.forEach((v) => {
        this.settleDrop.forEach((val) => {
          if (val.value == v.settleType) {
            val.disabled = true;
          }
        });
      });
    },
    // 修改结算周期
    editTab(formData, index) {
      this.formData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.userPayShow = true;
      this.settleDrop.forEach((v) => {
        v.disabled = false;
      });
      this.tabData.forEach((v) => {
        this.settleDrop.forEach((val) => {
          if (val.value == v.settleType) {
            val.disabled = true;
          }
        });
      });
    },
    //删除结算周期
    delTab(index, value) {
      this.$confirm("此操作将删除该结算周期, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delProducts(this.$route.query.id, value.settleType).then((res) => {
              if (res) {
                this.getDetail();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          } else {
            this.tabData.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算周期
    sureAdd() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.settleType) {
        this.$message.error("结算方式必选");
        return false;
      }
      if (!data.cycleType) {
        this.$message.error("周期类型必选");
        return false;
      }
      if (!data.settleDay) {
        this.$message.error("结算天数必填");
        return false;
      }
      if (this.$route.query.pageType == "edit") {
        setProducts(this.$route.query.id, data).then((res) => {
          if (res) {
            this.$message.success("保存成功");
            this.getDetail();
            this.userPayShow = false;
          }
        });
      } else {
        if (this.editIndex !== null) {
          this.tabData[this.editIndex] = data;
        } else {
          this.tabData.push(data);
        }
        this.$message.success("保存成功");
        this.userPayShow = false;
      }
    },
    //保存
    submit() {
      if (this.tabData.length <= 0) {
        this.$message.error("至少添加一个结算周期");
        return false;
      }
      let data = {
        mchDetail: this.subaccountDetail
          ? this.subaccountDetail.mchDetail
          : null,
        settlements: this.subaccountDetail
          ? this.subaccountDetail.settlements
          : null,
        products: this.tabData,
      };
      this.setSubaccount(data);
      addAllocmerchants(this.subaccountDetail).then((res) => {
        if (res) {
          this.$message.success("保存成功");
          this.setSubaccount(null);
          this.$router.push({
            name: "subaccountlist_shlb",
          });
        }
      });
    },
    // 页面跳转
    toUrl(name) {
      this.$router.push({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let data = {
        mchDetail: this.subaccountDetail
          ? this.subaccountDetail.mchDetail
          : null,
        settlements: this.subaccountDetail
          ? this.subaccountDetail.settlements
          : null,
        products: this.tabData,
      };
      this.setSubaccount(data);
    }
  },
};
</script>
<style scoped>
#fzcolor {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(72, 184, 182, 1);
  border-bottom: 3px solid rgba(72, 184, 182, 1);
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.xzjsxx {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(72, 184, 182, 1);
  margin-top: 19px;
  margin-bottom: 30px;
  cursor: pointer;
  display: inline-block;
}

.xzjsxx span {
  position: relative;
  top: -1px;
}

.lianjie {
  color: #008aff;
  padding: 0 5px 0 5px;
  cursor: pointer;
}

.tab tr td p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.tab_title {
  background: rgba(244, 245, 246, 1);
  border-top: 1px solid rgba(220, 224, 230, 1);
}

.tab1 .tab tr {
  padding-left: 20px;
  height: 60px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab1 {
  margin-top: 20px;
}

.jsxx {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(153, 153, 153, 1);
  padding-left: 14px;
}

.header span {
  text-align: center;
  display: inline-block;
  width: 100px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
}

.header {
  margin-bottom: 20px;
  line-height: 40px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

#mian {
  width: calc(100% - 41px);
  height: 97%;
  background: rgba(255, 255, 255, 1);
  padding: 0 20px;
  overflow-y: auto;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
